@import "../../src/style/color";

:root {
  --app-height: 100vh;
  --shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

* {
  // font-family: "Sarabun", sans-serif !important;
  font-family: "Kanit", sans-serif !important;
}

.main-content {
  margin: 25px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  min-height: 100vh;
  -webkit-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  @include breakpoint(xxs) {
    margin: 25px 10px;
    padding: 15px;
  }
  @include breakpoint(xxxs) {
    margin: 25px 10px;
    padding: 15px;
  }
}

.testobj {
  border: 1px solid red;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.boder-bottom-line {
  border-bottom: #f0f0f0 solid 1px;
  position: relative;
  &::before {
    position: absolute;
    background: rgb(245, 80, 96);
    background: linear-gradient(180deg, rgba(245, 80, 96, 1) 0%, rgba(185, 28, 49, 1) 100%) !important;
    width: 10%;
    height: 4px;
    left: 0;
    bottom: -1px;
    content: "";
  }
}

.header-title-customs {
  margin-bottom: 16px;
  h3 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
  }

  .subtitle {
    font-size: 16px;
    color: #353535;
  }
}

:where(.css-dev-only-do-not-override-2obq70).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: inherit;
}

:where(.css-dev-only-do-not-override-2obq70).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    visibility: hidden;
    margin-inline-end: 0px;
}

.brand-logo-checkslip {
  width: 250px;
}

.site-form-item-icon-custom {
  width: 16px;
  color: rgb(137, 137, 137);
  margin-right: 5px;
}

.title-highlight {
  background: #f62d49;
  background: linear-gradient(to right, #f62d49 0%, #900620 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-in {
  margin-top: 20px;
  // padding-bottom: 10px !important;
  padding: 0px 20px;
  // background: #e04152;
  // border-bottom: #ffe9e9 1px solid;
  text-align: center;
  img {
    width: 150px;
  }
  
}

.space-alert {
  margin-bottom: 16px;
}

.ant-dropdown {
  .ant-dropdown-menu {
    -webkit-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.5);
    svg {
      width: 16px;
      color: $red-color-300 !important;
    }
    .ant-dropdown-menu-title-content {
      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
