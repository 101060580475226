@mixin breakpoint($class) {

  @if $class ==xxxs {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if $class ==xxs {
    @media (max-width: 575px) {
      @content;
    }
  }

  @if $class ==xs {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $class ==sm {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @else if $class ==max-sm {
    @media (max-width: 991px) {
      @content;
    }
  }

  @else if $class ==md {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $class ==max-md {
    @media (max-width: 992px) {
      @content;
    }
  }

  @else if $class ==lg {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $class ==max-lg {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @else if $class ==ex-lg {
    @media (min-width: 1400px) {
      @content;
    }
  }

  @else if $class ==max-ex-lg {
    @media (max-width: 1399px) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}