.screen-modal-root{
  .regis-btn {
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 100 !important;
    transition: all 0.4s;
    width: 100%;
    border-radius: 10px;
    border: none;
    padding: 0.25rem;
    min-height: 85px;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    background: url(https://ezslot-vip.com/build/images/ez-theme-switcher-ic-register-bg.a4271207.png)
      no-repeat 50% / cover;
  }
}