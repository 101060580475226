.layout-history {
  z-index: 3;
  position: relative;
  .layout-main {
    // padding: 24px;
    backdrop-filter: blur(3px);
    background: rgba(245, 245, 245, 0.8) !important;
    .control-back-button {
      // margin-bottom: 25px;
      padding: 24px;
      justify-content: center;
      display: flex;
    }
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        width: 90vw;
        .ant-tabs-tab {
          width: 90vw;
          // text-align: center;
          padding: 12px 0px;
          font-size: 18px;
          font-weight: 500;
          justify-content: center;
          background: rgba(150, 210, 255, 0.4);
          .ant-tabs-tab-btn {
            color: #3b8cff;
          }
          &.ant-tabs-tab-active {
            background:#3b8cff ;
            .ant-tabs-tab-btn {
              color: white !important;

            }
          }
        }
      }
    }

    .ant-tabs-nav-more {
      display: none !important;
    }
    border-radius: 10px;
    margin-top: 2rem;
    background: #f5f5f5e8;
    .game-icon {
      max-width: 36px;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: -5px;
    }
    .menu-btn {
      cursor: pointer;
      border-radius: 10px;
      // border: #1cc8f2 1px solid;
      // color: #1cc8f2;
      // background: #edfbfe;
      justify-content: center;
      display: flex;
      border: #333333 1px solid;
      color: #333333;
      background: #ffef00;
      transition: all 0.2s;
      padding: 10px 14px;
      min-height: 40px;
      width: 100%;
      max-width: 500px;
      font-size: 1.1rem;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
      svg {
        margin-right: 6px;
      }
    }
    .back-btn {
      display: inline-flex;
      align-items: center;
      padding: 10px 14px;
      border-radius: 10px;
      border:1px solid #3b8cff;
      color: #3b8cff;
      svg {
        margin-right: 6px;
      }
      &:hover {
        color: white;
        background-color: #3b8cff;
       
      }
    }
    .ant-form-item-row {
      align-items: center;
    }
  }
}
.form-search {
  // display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  .ant-input {
    width: 50%;
    margin-right: 1rem;
  }
  .ant-btn {
    background: #3b8cff;
    color: white;
    border: 0px;
    border-radius: 10px;
    padding: 0px 30px;
    &:hover {
      color: #fdef4b !important;
    }
  }
}
// .ant-tabs-nav {
//   width: 100% !important;
//   }

// .ant-tabs-tab {
// display: block; // centers text inside tabs
// flex: 1;
// text-align: center;
// }

// .ant-tabs-content-holder {
//   display: flex;
// }
