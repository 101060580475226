.footerbar-main{

}
.van-grid,.van-tabbar-item {
  display: -ms-flexbox;
  display: flex
}

.van-tabbar-item {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  color: #3e768d;
  cursor: pointer;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  line-height: 1
}

.van-tabbar-item {
  -ms-flex-pack: end;
  // font-size: .24rem;
  justify-content: flex-end;
  position: relative;
}

.van-tabbar-item__icon {
  font-size: 26px;
  margin-bottom: 2px;
  position: relative
}

.van-tabbar-item__icon .van-icon {
  display: block
}

.van-tabbar-item__icon img {
  display: block;
  height: 20px
}

.van-tabbar-item--active {
  background-color: #fff;
  color: #1989fa
}

.van-tabbar-item .van-info {
  margin-top: 4px
}

.van-tabbar {
  height: 3.24rem;
  background-color: #fff;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: -ms-flexbox;
  display: flex;
  // height: 50px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 1
}

.van-tabbar--fixed {
  bottom: 0;
  left: 0;
  position: fixed
}

.van-tabbar--unfit {
  padding-bottom: 0
}
.van-tabbar-item__text {
 white-space: nowrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  // height: .54rem;
  line-height: 1.1;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: pre-wrap
}
