@import "../../style/mixin";

// .ant-modal-root > 
.nav-modal {
  @include breakpoint(xxxs) {
    max-width: calc(100vw - 0px) !important;
    margin: 0 !important;
    height: 100%;
  }

  @include breakpoint(xxs) {
    max-width: calc(100vw - 0px) !important;
    margin: 0 !important;
    height: 100%;
  }

  background-color :rgb(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  height: auto !important;

  // max-width: 450px;
  .logoNav {
    max-width: 180px;
    // margin-top: 25px;
  }

  .ant-modal-content {
    background: none !important;

    @include breakpoint(xxxs) {
      padding: 16px 12px;
    }

    @include breakpoint(xxs) {
      padding: 16px 12px;
    }

    .ant-modal-close {
      color: #0565d3;
      font-weight: bold;
      background: none !important;
    }

    .ant-modal-close-x {
      color: #0565d3;
      font-weight: bold;
      background: none !important;
    }

    .show-username {
      // border: 1px solid rgba(0, 123, 255, 0.8);
      // background: rgba(7, 69, 146, 0.7);
      // background: yellow;
      // color: rgba(7, 69, 146, 0.7);
      // border-radius: 12px;
      font-size: 18px;
    }

    .regis-btn {
      color: white;
      font-size: 1.1rem;
      font-weight: 100 !important;
      transition: all 0.4s;
      width: 100%;
      border-radius: 10px;
      border: none;
      padding: 0.25rem;
      min-height: 85px;
      position: relative;
      overflow: hidden;
      box-shadow: none;
      background: url(https://ezslot-vip.com/build/images/ez-theme-switcher-ic-register-bg.a4271207.png) no-repeat 50% / cover;
    }

    .menu-btn {
      cursor: pointer;
      border-radius: 10px;
      box-shadow: none;
      border: #1cc8f2 1px solid;
      color: #1cc8f2;
      background: #edfbfe;
      transition: all 0.2s;
      min-height: 40px;
      width: 100%;
      font-size: 1.1rem;
    }
  }

}

.ant-modal-mask {
  background: hsla(0, 0%, 0%, 0.5) !important;
  backdrop-filter: blur(2px);
}

.social-share {
  margin-top: 15px;
  display: block;
}

.switch-language {
  // position: absolute;
  top: 5px;
  left: 5px;
}

.welcome-user {
  margin-top: 10px;
  border-radius: 10px;
  background: rgb(238, 251, 255);
  // border: 1px solid #0565d3;
  padding: 15px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: #0565d3;
  }
}

.tabs-login {
  .ant-tabs-nav {
    margin: 0 0 8px 0;

    &::before {
      border-bottom: 1px solid #d5d5d5;
    }
  }

  h3 {
    color: #0565d3;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;

    @include breakpoint(xxxs) {
      margin-top: 0px;
    }

    @include breakpoint(xxs) {
      margin-top: 0px;
    }

  }

  h4 {
    color: #0565d3;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;

    @include breakpoint(xxxs) {
      margin-top: 0px;
    }

    @include breakpoint(xxs) {
      margin-top: 0px;
    }

  }

  .content-turnover {
    background: #f7f7f7;
    padding: 16px;
    border-radius: 6px;

    .text-note {
      background: #e9e9e9;
      text-align: center;
      display: block;
      padding: 12px 6px;
      border-radius: 4px;
    }
  }
}