@import "../../../src/style/mixin";

.promotion-main {
  padding: 60px 25px;
  position: relative;
  z-index: 2;
  overflow-x: clip;
  @include breakpoint(xxxs) {
    padding: 20px 25px;
  }
  @include breakpoint(xxs) {
    padding: 20px 25px;
  }

  .bg-promotion {
    background: url(../../assets/bg-promotion.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 774px;
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
    bottom: 0;
    @include breakpoint(max-lg) {
      min-height: 684px;
    }
    @include breakpoint(xxxs) {
      min-height: 400px;
      height: auto;
    }
    @include breakpoint(xxs) {
      min-height: 400px;
      height: auto;
    }
  }

  .carousel-container {
    justify-content: center;
    align-content: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    .control-ch {
      width: 460px ;
      position: absolute;
      z-index: 5;
      @include breakpoint(max-lg) {
        width: 380px;
      }
       @include breakpoint(max-md) {
        display: none;
      }
      @include breakpoint(xxxs) {
        display: none;
      }
      @include breakpoint(xxs) {
        display: none;
      }
  
    }
    .control-ch-left {
      top: -130px;
      left:0;
      @include breakpoint(max-lg) {
        top: -90px;
        left: -150px;
      }
    }
    .control-ch-right {
      top: -135px;
      right: 0;
      @include breakpoint(max-lg) {
        top: -105px;
        right: -130px;
      }
    }
    .-col-video-wrapper {
      position: relative;
      width: 700px;
      margin-top: 40px;
      @include breakpoint(max-lg) {
        margin-top: 0px;
      }
       @include breakpoint(md) {
       
      }
      .-video-wrapper {
        position: relative;
        // overflow: hidden;
        display: block;
        unicode-bidi: isolate;
        .-video-frame {
          position: relative;
          z-index: 2;
          width: 100%;
          max-width: 100%;
          height: auto;
          aspect-ratio: auto 960 / 1949;
        }
        .-video {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          width: 96%;
          height: auto;
          border-radius: 6%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
    .carousel-image {
      width: 100%;
      max-width: 350px;
      height: auto;
      margin: 0 auto;
    }
  }
  .title-promotion {
    position: absolute;
    z-index: 5;
    width: 600px;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include breakpoint(xxxs) {
      width: 300px;
    }
    @include breakpoint(xxs) {
      width: 300px;
    }
  }
}
