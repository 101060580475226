.sider-check-slip {
  // background: rgb(245, 80, 96);
  // background: linear-gradient(180deg, rgba(245, 80, 96, 1) 0%, rgba(185, 28, 49, 1) 100%) !important;
  background: rgb(206, 22, 56);
  background: linear-gradient(180deg, rgb(212, 30, 63) 0%, rgba(89,21,42,1) 100%) !important;

  ul {
    li {
      .ant-menu-title-content {
        display: flex;
        align-items: center;
        margin-inline-start: 0px !important;
      }
      a {
        display: flex;
        align-items: center;
      }
      svg {
        width: 16px;
        margin-right: 15px;
      }
    }
  }
  :where(.css-dev-only-do-not-override-1xg9z9n).ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background: #cb3042;
  }
  .ant-menu-dark {
    background: transparent !important;
    .ant-menu-item-selected {
      // background: rgb(189, 9, 40);
      // background: linear-gradient(187deg, rgba(189, 9, 40, 1) 0%, rgba(187, 8, 41, 1) 100%);
      background: rgb(247,46,70);
      background: linear-gradient(90deg, rgba(247,46,70,1) 0%, rgba(222,0,26,1) 100%);

      // background: #d83c4a !important;
      -webkit-box-shadow: 0px 0px 17px -1px rgba(102, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 17px -1px rgba(102, 0, 0, 0.2);
      box-shadow: 0px 0px 17px -1px rgba(102, 0, 0, 0.2);
      svg {
        color: #ff9393;
      }
    }
  }
  .ant-menu-sub {
    // background: rgb(245, 80, 96) !important;
    background: rgb(134 26 50) !important;
    .ant-menu-item {
      padding-left: 16px !important;
      display: flex;
      align-items: center;
      .ant-menu-title-content {
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          background-color: rgb(235, 171, 177);
          border-radius: 30rem;
          margin-right: 10px;
          margin-left: 24px;
        }
      }
      svg {
        margin-left: 16px !important;
        margin-right: 0px !important;
      }
    }
  }
}

.header-check-slip {
  display: flex;
  justify-content: space-between;
  background: white;
  padding-left: 0;
  padding-right: 25px;
  -webkit-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
  .logout-style {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
    }
  }
}

:where(.css-dev-only-do-not-override-rzehzp).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  visibility: hidden;
  margin-inline-end: 0px;
}

:where(.css-dev-only-do-not-override-rzehzp).ant-form-vertical .ant-form-item-label >label::after {
  display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: inherit;
}

.ant-switch-checked {
  background: $red-color-100 !important;
}