.layout {
  min-height: 100vh;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
}

.logo {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.popular-games img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.ant-picker-dropdown {
  @media (max-width: 768px) {
    inset: 51.9333px auto auto 0px !important;
  }
}
.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 768px) {
    max-width: 100vw !important;
    overflow: auto !important;
    inset: 51.9333px auto auto 0px !important;
  }
}