@import "../../../src/style/mixin";

.layout-body { 
  background: url(../../assets/bg-header.png) no-repeat #1f6bc6;
  background-size: contain;
  position: relative;
  .header-announce {
    position: relative;
    margin: 30px 10px;
    z-index: 3;
    text-align: center;
    border-radius: 30rem;
    padding: 12px 0px;
    color: white;
    font-size: 24px;
    backdrop-filter: blur(2px);
    background-color: rgb(9, 83, 196,0.8);
    border: 2px solid #81e7fe;
    box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
    -webkit-box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
    -moz-box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
    @include breakpoint(max-lg) {
      margin: 10px 10px;
    }
    @include breakpoint(xxxs) {
      font-size: 14px;
    }
     @include breakpoint(xxs) {
      font-size: 14px;
      padding: 2px 6px;
    }
    // @include breakpoint(max-md) {
    //   display: none;
    // }
    // @include breakpoint(xxs) {
    //   display: none;
    // }
    // @include breakpoint(xxxs) {
    //   display: none;
    // }
  }
    .ch-shark {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 2;
      width: 468px;
      @include breakpoint(max-lg) {
        width: 347px;
      }
      @include breakpoint(max-md) {
        display: none;
      }
      @include breakpoint(xxs) {
        display: none;
      }
      @include breakpoint(xxxs) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  .main-header {
    background: transparent;
    // background: linear-gradient(0deg, rgba(27,149,254,1) 0%, rgba(26,92,224,1) 100%);
    // -webkit-box-shadow: 0px 0px 34px -13px rgba(0, 31, 74, 0.6);
    // -moz-box-shadow: 0px 0px 34px -13px rgba(0, 31, 74, 0.6);
    // box-shadow: 0px 0px 34px -13px rgba(0, 31, 74, 0.6);
    padding: 0px;
    display: flex;
    color: white;
    height: 70px;
  }
  .content-main {
    background: none;
    .layout-lobby {
      padding: 20px;
      margin-top: 20px;
      background: none;
      position: relative;
      z-index: 2;
      @include breakpoint(max-lg) {
        margin-top: 0px;
      }
      @include breakpoint(xxxs) {
        padding:0 12px;
      }
       @include breakpoint(xxs) {
        padding:0 12px;
      }
    }
  }
}
