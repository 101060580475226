@import "./color";

// button-main

.button-main {
  color: #ffffff;
  background: rgb(255, 158, 0);
  background: linear-gradient(0deg, rgba(255, 158, 0, 1) 0%, rgba(255, 221, 0, 1) 100%);
  -webkit-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.55);
  -moz-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.55);
  box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.55);
  background-size: 300% 100% !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1rem;
  line-height: 1.375rem;
  background-position: 0% 0% !important;
  padding: 0px 23px;
  border-radius: 30rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px !important;
  cursor: pointer;
  .ant-btn-icon {
    margin-inline-end: 0px !important;
  }
  svg {
    width: 16px;
    margin-right: 6px;
  }
  &:hover {
    background: rgb(255, 158, 0);
    background: linear-gradient(0deg, rgba(255, 158, 0, 1) 0%, rgba(255, 221, 0, 1) 100%) !important;
    background-position: 100% 0% !important;
    transition: all 0.4s ease-in-out !important;
    -webkit-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
    -moz-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
    box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
  }
  &:disabled {
    opacity: 40%;
    // background: #f5f5f5 !important;
    color: #ffffff;
    // border-color: #d9d9d9 !important;
    &:hover {
      box-shadow: none;
      background-position: 0% 0% !important;
    }
  }
}

// button-main

// button-main

.button-main-line {
  color: white;
  background:transparent;
  border: #ff9e00 1px solid;
  background-size: 300% 100% !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1rem;
  line-height: 1.375rem;
  background-position: 0% 0% !important;
  padding: 0px 15px;
  border-radius: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn-icon {
    margin-inline-end: 0px !important;
  }
  svg {
    width: 16px;
    margin-right: 5px;
  }
  &:hover {
    color: white !important;
    border: #ff9e00 !important;
    background-position: 100% 0% !important;
    transition: all 0.4s ease-in-out !important;
    background: #ff9e00 !important;
  }
  &:disabled {
    background: $gray-color-100 !important;
    color: $gray-color-300 !important;
    border-color: $gray-color-200 !important;
    &:hover {
      background: $gray-color-100 !important;
      color: $gray-color-300 !important;
      border-color: $gray-color-200 !important;
    }
  }
}

// button-main

// button-main-green

.button-main-green {
  color: #ffffff;
  background: #30e6b0;
  box-shadow: 0px 0px 10px #12d2006e;
  background-size: 300% 100% !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1rem;
  line-height: 1.375rem;
  background-position: 0% 0% !important;
  &:hover {
    background-position: 100% 0% !important;
    transition: all 0.4s ease-in-out !important;
    box-shadow: 0px 0px 30px #048f2e6e;
  }
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #26e2aa !important;
  }
}

// button-back
.button-back {
  background: $gray-color-100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 30rem;
  cursor: pointer;
  transition: all 0.4s ease-in-out !important;
  &:hover {
    background: $gray-color-150;
  }
  svg {
    color: red;
  }
}

// button-text-danger
.button-text-danger {
  color: $red-color-100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  &:hover {
    color: $red-color-100;
    background: $red-color-50;
    transition: all 0.4s ease-in-out !important;
  }
  svg {
    width: 16px;
    margin-right: 5px;
  }
}

// button-main

.button-line-black {
  color: $black-color-100;
  background: rgb(255, 255, 255);
  border-color: $gray-color-300;
  background-size: 300% 100% !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1rem;
  line-height: 1.375rem;
  background-position: 0% 0% !important;
  padding: 0px 15px;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn-icon {
    margin-inline-end: 0px !important;
  }
  svg {
    color: $red-color-100;
    width: 16px;
    margin-right: 5px;
  }
  &:hover {
    color: $black-color-100 !important;
    background: $black-color-100 !important;
    border-color: $gray-color-300 !important;
    transition: all 0.4s ease-in-out !important;
    background: $gray-color-150 !important;
  }
  &:disabled {
    background: $gray-color-100 !important;
    color: $gray-color-300 !important;
    border-color: $gray-color-200 !important;
    &:hover {
      background: $gray-color-100 !important;
      color: $gray-color-300 !important;
      border-color: $gray-color-200 !important;
    }
  }
}

// button-main

.button-line-friends  {
  color: #ffffff;
  background: $gradient-green-primary !important;
  background-size: 300% 100% !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1rem;
  line-height: 1.375rem;
  background-position: 0% 0% !important;
  padding: 0px 23px;
  border-radius: 30rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px !important;
  cursor: pointer;
  .ant-btn-icon {
    margin-inline-end: 0px !important;
  }
  svg {
    width: 16px;
    margin-right: 6px;
  }
  &:hover {
    background: $gradient-green-primary !important;
    background-position: 100% 0% !important;
    transition: all 0.4s ease-in-out !important;
    -webkit-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
    -moz-box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
    box-shadow: 0px 20px 21px -10px rgba(25, 91, 223, 0.83);
  }
  &:disabled {
    opacity: 40%;
    // background: #f5f5f5 !important;
    color: #ffffff;
    // border-color: #d9d9d9 !important;
    &:hover {
      box-shadow: none;
      background-position: 0% 0% !important;
    }
  }
}
