.wallet-root{
  min-width: 150px;
  height: 38px;
  border-radius: 12px;
  border: 1px solid rgba(0, 123, 255, .8);
  background: rgb(7, 69, 146,0.7);
  // box-shadow: var(--shadow);
  text-decoration: none;
  font-size: 18px;
  color: white;
  position: relative;
  // text-shadow: var(--text-shadow);
  padding: 0px 15px;
  padding-left: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  .text-number {
    margin-right: 6px;
  }
  img {
    width:54px;
    margin-right: 8px;
    position: absolute;
    left: -30px;
    top: -12px;
  }
}