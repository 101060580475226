
$red-color-300:#d92323cf;
$red-color-100: #f04d5d;
$red-color-50: #ffdadf;


$black-color-100: #2a2a2a;

$gray-color-300: #a3a3a3;
$gray-color-200: #d6d6d6;
$gray-color-150: #e7e7e7;
$gray-color-100: #f5f5f5;
$gray-color-50: #f7f7f7;
$gray-color-0: #f1f1f1;




$green-color-400: #00b900;
$green-color-100: #31E6B0;
$green-color-50: #e0fff6;



$gradient-red-primary: linear-gradient(150deg, rgb(234, 0, 0) 0%, rgba(117, 0, 0, 1) 35%, rgba(234, 0, 0, 1) 68%, rgb(117, 0, 0) 100%);
$gradient-green-primary:linear-gradient(0deg, rgba(1,186,1,1) 0%, rgba(108,237,108,1) 100%);
$gradient-red-primary-3:linear-gradient(90deg, rgba(217,35,35,1) 0%, rgba(117,0,0,1) 100%);
