@import "./color";


.radio-group-checkslip {
  // padding: 0px 60px;
  margin-bottom: 0px !important;
  .ant-radio-group {
    width: 100%;
    // background: $gray-color-100;
    border: 1px solid $red-color-100;
    padding: 3px;
    border-radius: 8px;
    overflow: hidden;
    // display: flex;
    // align-items: center;
  }


  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    background:white;
    border:none;
    color: $red-color-100;
    &::before {
      width: 0px;
    }
    &:first-child {
      border-radius: 10px !important;
    }
    &:last-child  {
      border-radius: 10px !important;
    }
    &:hover {
      color:$red-color-300;
    }
    &.ant-radio-button-wrapper-checked {
      // background: $gradient-red-primary-3 !important;
      border-radius: 6px !important;
      // background: red !important;
    }
    .radio-button-checkslip {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}