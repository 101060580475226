@import "../../style/mixin.scss";

.owl-container {
  .game-name {
    .ant-card-meta-title {
      color: white;
      font-size: 18px;
    }
  }
  .owl-theme {
    
    .owl-dots {
      .owl-dot {
        &.active {
          span {
            background: #fdef4b !important;
          }
        }
        span {
          background: #fffdda !important;
          width: 30px !important;
          height: 5px !important;
          &:hover {
            background:#fdef4b !important;
          }
        }
      }
    }
  }
  .owl-carousel {
    margin-top: 36px;
    @include breakpoint(xxs) {
      margin-top: 0px;
     }
    .owl-stage-outer {
      .owl-stage {
        width: auto;
        .owl-item {
          overflow: hidden;
          border-radius: 15px;
        }
      }
    }
  }
}
.promoModal{
  .ant-modal-content {
    .ant-modal-close-x{
      font-size: 25px !important;
    }
    .ant-modal-close {
      color: white !important;
      top: -40px !important;
      font-size: 40px !important;
      // right: -30px !important;
    }
  }
}