.container-customs {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    @include breakpoint(xxs) {
      max-width: 540px;
    }
    @include breakpoint(sm) {
      max-width: 720px;
    }
    @include breakpoint(md) {
      max-width: 960px;
    }
    @include breakpoint(lg) {
      max-width: 1140px;
    }
    @include breakpoint(ex-lg) {
      max-width: 1320px ;
    }
    @include breakpoint(max-ex-lg) {
      max-width: 1320px;
    }

}

.text-gray {
  color: $gray-color-300;
}


.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}


.button-menu {
  display: block;
  width: 100%;
  height: 83px;
  transition: 0.3s all;
  &.button-popular {
    background-image: url(../assets/button-popular.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background-image: url(../assets/button-popular-hover.png);
      background-size: contain;
    }
  }
  &.button-casino {
    background: url(../assets/button-casino.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background: url(../assets/button-casino-hover.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  &.button-slot {
    background: url(../assets/button-slot.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background: url(../assets/button-slot-hover.png);
      background-size: contain;
    }
  }
  &.button-sport {
    background: url(../assets/button-sport.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background: url(../assets/button-sport-hover.png);
      background-size: contain;
    }
  }
  &.button-fish {
    background: url(../assets/button-fish.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background: url(../assets/button-fish-hover.png);
      background-size: contain;
    }
  }
  &.button-lottery {
    background: url(../assets/button-lottery.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &:hover {
      background: url(../assets/button-lottery-hover.png);
      background-size: contain;
    }
  }
  .menu-text-main {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.4s;
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 100px;
    &:hover {
      color: #fdef4b;
    }
  }
}