@import "../../style/mixin";

.catagory-menu {
  ul {
    // flex-direction: row !important;
    // margin-top: 0;
    // display: flex;
    padding-left: 0;
    // margin-bottom: 0;
    list-style: none;
    gap: 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    li {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      a {
        align-items: center;
        padding: 12px 16px;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        border: none;
        width: 100%;
        display: flex;
        z-index: 1;
        padding: 0;
        display: inline-block;
        position: relative;
        box-shadow: none;
        .button-menu {
          height: 100px;
          min-width: 93px;
          @include breakpoint(xxs) {
            height:51px;
          }
          .menu-text-main {
            padding-left: 120px;
            @include breakpoint(xxxs) {
              padding-left: 50px;
              font-size: 14px;
            }
             @include breakpoint(xxs) {
              padding-left: 58px;
              font-size: 15px;
            }
          }
        }
        img{
          max-width: 240px;
        }

      }
    }
  }
}
