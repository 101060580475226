.sider-root{
  max-width: 208px;
  width: 100%;
  .sider-main{
    background: unset !important;
    .ant-menu{
      background: unset !important;
      border: unset !important;
      width: 100%;
      .ant-menu-item-selected{
        background: none !important;
        background-color: none !important;
      }
      .ant-menu-item{
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0;
        height: 100%;
        margin-bottom: 14px;
        // .menu-text-main {
        //   white-space: nowrap;
        //   font-size: 1rem;
        //   margin-left: 1.25rem;
        //   left: 50%;
        //   -webkit-transform: translate(-50%, -60%);
        //   transform: translate(-50%, -60%);
        //   transition: all 0.4s;
        //   position: absolute;
        //   top: 50%;
        //   color: #9e5045;
        // }
      }
    }
  }
}