.card-data-list{
  padding: 10px;
  .sum-row{
    padding: 5px 10px;
  }
  .row-data{
    background: white;
    border-radius: 8px;
    color: #000000;
    font-weight: 300;
    padding: 5px 10px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
    .title{
      color: #a6a6a6;
    }
  }
}