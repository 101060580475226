@import "../../../src/style/mixin";

.webinfo-root{
  // background-image: url(../../assets/bg-info.jpg);
  // background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  .bg-webinfo {
    background: url(../../assets/bg-info.png);
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 863px;
    position: absolute;
    left: 0;
    right:0;
    bottom: 0;
    z-index: 0;
  }
  .content-webinfo {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: 1.5fr  2fr;
    align-items: center;
    padding-bottom :40px ;
    @include breakpoint(max-md) {
      grid-template-columns:1fr;
    }
     @include breakpoint(md) {
      grid-template-columns: 1fr  2fr;
    }
    // @include breakpoint(xxxs) {
    //   display: none;
    // }
    //  @include breakpoint(xxs) {
    //   display: none;
    // }
    .ch-info {
      @include breakpoint(max-md) {
        width: 70%;
        margin: 0 auto;
      }
    }
    .detail-text-webinfo {
      border-radius: 12px;
      padding: 24px 32px;
      margin-left: 40px;
      margin-right: 70px;
      color: white;
      font-size: 18px;
      backdrop-filter: blur(2px);
      background-color: rgb(9, 83, 196,0.8);
      border: 2px solid #81e7fe;
      box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
      -webkit-box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
      -moz-box-shadow: -1px 1px 119px -27px rgba(129,231,254,1.0) inset;
      @include breakpoint(max-md) {
        margin-left: 25px;
        margin-right: 25px;
        text-align: center;
      }
        @include breakpoint(xxxs) {
          padding: 16px 24px;
          margin-left: 15px;
          margin-right: 15px;
      }
       @include breakpoint(xxs) {
          padding: 16px 24px;
          margin-left: 15px;
          margin-right: 15px;
      }
      h2 {
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: 600;
      }
      .text-webinfo {
        line-height: 1.6;
      }
    }
  }
}