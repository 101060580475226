.hearder-content {
  display: flex;
  width: 100%;
  .logo {
    width: 144px;
    position: relative;
    z-index: 3;
    margin-top: 13px;
    img {
      width: 100%;
    }
  }
  .header-top-menu {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 25px;
    padding: 18px 0px;
    // -webkit-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
    // -moz-box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
    // box-shadow: 0px 0px 34px -13px rgba(0, 0, 0, 0.15);
    width: 100%;
    .logout-style {
      display: flex;
      align-items: center;
      svg {
        width: 16px;
      }
    }
  }
}

.hamberger-btn {
  margin-right: 15px;
  margin-top: 6px;
  width: 25px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  .user-icon{
    margin-left: -10px;
    margin-top: -5px;
  }
  span {
    &:first-child {
      -webkit-transform-origin: left center;
      transform-origin: left center;
      top: 0;
      -webkit-animation: x-hamburger-wave 0.4s ease-out infinite alternate;
      animation: x-hamburger-wave 0.4s ease-out infinite alternate;
    }
    &:nth-child(2) {
      -webkit-transform-origin: left center;
      transform-origin: left center;
      top: 9px;
      -webkit-animation: x-hamburger-wave 0.4s ease-out 0.3s infinite alternate;
      animation: x-hamburger-wave 0.4s ease-out 0.3s infinite alternate;
    }
    &:nth-child(3) {
     top: 18px;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-animation: x-hamburger-wave .4s ease-out .6s infinite alternate;
    animation: x-hamburger-wave .4s ease-out .6s infinite alternate;
    }
    &:nth-child(4) {
      top: 27px;
     -webkit-transform-origin: left center;
     transform-origin: left center;
     -webkit-animation: x-hamburger-wave .4s ease-out .2s infinite alternate;
     animation: x-hamburger-wave .4s ease-out .2s infinite alternate;
     }
    width: 70%;
    display: block;
    position: absolute;
    height: 4px;
    background: yellow;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
}

@keyframes x-hamburger-wave {
  0% {
    width: 70%;
  }

  to {
    width: 100%;
  }
}
