@import "../../style/mixin";

.lobby-games {
  .content-lobby-game {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include breakpoint(xxxs) {
      margin-bottom: 10px;
    }
    @include breakpoint(xxs) {
      margin-bottom: 10px;
      display: block;
    }
    .button-search-customs {
      border-radius: 3rem;
      overflow: hidden;
      @include breakpoint(xxxs) {
        // display: none;
      }
      @include breakpoint(xxs) {
        // display: none;
      }
      .ant-input {
        padding-left: 20px;
        height: 40px;
        border: 1px;
      }
      .ant-input-group-addon {
        button {
          background: white;
          border: 0px;
          @include breakpoint(xxs) {
            height: 30px;
          }
          svg {
            color: #1f6bc6;
          }
        }
      }
    }
    .button-search-mobile {
      .ant-input-group-addon {
        button {
          background: white;
          border: 0px;
          svg {
            color: #1f6bc6;
          }
        }
      }
    }
    .search-bar {
      margin-top: -40px;
      .ant-collapse-header {
        height: 0px;
        // display: none;
      }
      .ant-collapse-expand-icon {
        display: none;
      }
    }
    .game-type-txt {
      margin-top: 0px;
      text-align: left;
      color: #fdef4b;
      align-items: baseline;
      font-weight: bold;
      font-size: 36px;
      @include breakpoint(xxxs) {
        font-size: 26px;
      }
      @include breakpoint(xxs) {
        margin-bottom: 10px;
        font-size: 22px;
      }
      .count {
        font-size: 18px;
        line-height: normal;
        font-weight: 100;
        padding-left: 10px;
        color: #fdef4b;
        margin-top: 0px;
      }
    }
  }

  .card-game {
    border: none;
    background: none !important;
    cursor: pointer;
    .ant-card-head-title {
      display: flex;
      justify-content: center;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 12px;
    }
    .ant-card-head {
      border: none;
      padding: 0;
    }
    .ant-card-body {
      padding: 8px 6px;
      height: 36px;
      .game-name {
        text-align: left;
        font-size: 18px;
      }
    }
    .ant-card-meta-title {
      font-size: 16px;
      @include breakpoint(xxxs) {
        font-size: 14px;
      }
       @include breakpoint(xxs) {
        font-size: 14px;
      }
      font-weight: 300;
    }
    .-overlay {
      // margin-top: 20px;
      position: relative;
      z-index: 3;
      color: white;
      opacity: 0;
      transform: translateY(-70px);
      transition: 0.5s;
    }
    &:hover {
      .-overlay {
        opacity: 1;
        transform: translateY(-160px);
      }
      .sub-game {
        margin-top: 30%;
      }
      .ant-card-head {
        display: flex !important;
        position: relative;
        transition: 0.4s ease-out;
        overflow: hidden;
        border-radius: 15px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 15px;
          background: rgba(black, 0.6);
          z-index: 2;
          transition: 0.5s;
          opacity: 1;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
  .maintenance {
    .ant-card-head {
      display: flex !important;
      position: relative;
      // transition: 0.4s ease-out;
      overflow: hidden;
      border-radius: 15px;
      .ant-card-head-wrapper {
        .ant-card-head-title {
          img {
            filter: grayscale(63%);
          }
        }
      }
      &::before {
        content: "";
        position: absolute;

        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: rgba(black, 0.6);
        z-index: 2;
        transition: 0.5s;
        opacity: 1;
      }
    }
    .ant-card-body {
      cursor: not-allowed;
      .-overlay {
        margin-top: 20px;
        opacity: 1;
        transform: translateY(0px);
        // transform: translateY(-160px);
        top: 25%;
        position: absolute;
        text-align: center;
        justify-content: center;
        align-self: center;
        width: 100%;
        left: 0px;

        .-overlay-inner {
          .-wrapper-container {
            .btn {
              background: none !important;
              border: none !important;
              font-size: 1.2em;
              display: block;
              cursor: not-allowed;
              &:hover {
                background: none !important;
                border: none !important;
              }
            }
          }
        }
      }
      .sub-game {
        margin-top: 30%;
      }
    }
  }
}
.custom-modal {
  max-width: 100% !important;
  margin: 0px !important;
  text-align: center !important ;
  @include breakpoint(xxxs) {
    margin: 0px !important;
  }
  .ant-modal-content {
    padding: 0px !important;
    // padding: 5px 10px !important;
    .ant-modal-close {
      top: 0px !important;
      color: white;
      inset-inline-end: unset;
      background: linear-gradient(0deg, rgb(255 10 0) 0%, rgb(251 168 2) 100%);
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    }
    .close-game {
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background: red;
      color: white;
      cursor: pointer;
    }
    .ant-modal-header {
      .ant-model-title {
        font-size: 8px !important;
      }
    }
  }
}

.modelGame {
  font-size: 8px !important;
}
.custom-modal .ant-modal-title {
  font-size: 8px !important;
}
