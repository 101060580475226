@import "../../../style/mixin";

.footer-main{
  margin: 0;
  padding-top: 130px;
  text-align: center;
  background: url(../../../assets/bg-footer.jpg);
  background-position: bottom;
  background-size: cover;
  @include breakpoint(xxxs) {
    padding-top: 40px;
  }
  @include breakpoint(xxs) {
    padding-top: 40px;
  }
  .tree{
    width: 405px;
    // right: -50px;
    bottom: 0px;
    position: absolute;
    // margin: 0;
    // padding: 0;
  }
  h2 {
    margin: 0px;
    padding-top: 20px;
  }
  .container{
    // padding: 20px 8%;
    .partner-list{
      list-style: none;
      padding: 10px 0px;
      text-align: center;
      padding-left: 0px;
      margin-bottom: 0px;
      li{
        display: inline-block;
        padding-left: 2%;
        padding-right: 2%;
        img {
          max-width: 70px;
        }
      }
    }
  }
  
}